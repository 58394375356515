import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["formField"]

    addNewField() {
        event.preventDefault();
        var newElement = $(this.formFieldTargets[this.formFieldTargets.length -1]).clone(true);
        newElement = this.updateFieldIncrement(newElement);
        
        newElement.insertAfter(".option-form:last");
    }

    removeNewField() {
        event.preventDefault();
        if (this.formFieldTargets.length === 1) {
            return
        }
        var element = this.formFieldTargets[this.formFieldTargets.length - 1];
        $(element).remove();
    }

    removeDealItem() {
        event.preventDefault();
        var itemId = event.target.dataset.itemId;
        if (itemId) {
            var dealId = event.target.dataset.dealId;
            $.ajax({
                type: 'DELETE',
                url: '/admin/daily_deals/' + dealId + '/daily_deal_items/' + itemId + ".js",
                 success: function(){  
                    console.log(itemId+"removed");
                },
                error: function(XMLHttpRequest, textStatus, errorThrown) { 
                    console.log("Status: " + textStatus); console.log("Error: " + errorThrown); 
                } 
            });
        }
        let itemRow = event.target.closest('.option-form');
        $(`#option-form-${itemId} + input[type='hidden']`).remove();
        itemRow.remove();
    }

    updateFieldIncrement(element, count) {
        event.preventDefault();
        var textFields = element.find('input[type=text], textarea, select, input[type=number]');        
        textFields.each(function () {
            $(this).val("");
            var currentID = $(this).attr("id");
            var currentIDNum = parseInt(currentID.match(/-?\d+\.?\d*/));
            var newID = currentID.replace(/[0-9]/g, currentIDNum+1);
            $(this).attr("id", newID);

            var currentName = $(this).attr("name");
            var newName = currentName.replace(/[0-9]/g, currentIDNum+1);
            $(this).attr("name", newName);

        });

        var button = element.find('button')[0];
        button.removeAttribute('data-item-id');

        return element;
    }
}